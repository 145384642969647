import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
  },
  Sub: {
    fontSize: 16,
  },
  Parrafo: {
    paddingLeft: 10,
  },
}));
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//H1 TITULO DE QUE SE DEDICA LA EMPRESA
export const Huno = () => {
  const classes = useStyles();

  return (
    
    <Typography
      className={classes.title}
      itemprop="name" 
      component="h1"
      color="textSecondary"
      gutterBottom
    >
      Cámaras de seguridad
    </Typography>
  );
};

//SPAN NOMBRE EMPRESA
export const Htext = () => {
  const classes = useStyles();

  return (
    
    <Typography
      className={classes.title}
      component="p"
      color="textSecondary"
      gutterBottom
    >
      Ich Sistemas
    </Typography>
    
  );
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//H2 acordeon seccion camaras de seguridad
/////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Hacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
     itemprop="name"
      className={classes.title}
      component="h2"
      color="secondary"
      gutterBottom
    >
      Cámaras de seguridad
    </Typography>
    
  );
};

//H3 acordeon subtitulo seccion camaras de seguridad//
export const Htacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    itemprop="name"
    className={classes.Sub}
      component="h3"
      color="inherent"
      gutterBottom
    >
      Nuestros servicios de cámaras de seguridad
    </Typography>
    
  );
};
//Parrafo acordeon subtitulo seccion camaras de seguridad
export const Pacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="p"
    >
    <span itemprop="description">
      En Ich Sistemas nos preocupa su seguridad por eso usamos las mejores tecnologías y contamos con los siguientes servicios.
      </span>
       <ul itemprop="hasOfferCatalog" itemscope itemtype="https://schema.org/OfferCatalog">
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
    
      <strong>Instalación de cámaras de seguridad.</strong>
    </Typography>
        </li>
        <dd>
        Tecnología Análoga.
        </dd>
        <dd>
        Tecnología IP.
        </dd>


        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Mantenimiento de equipo CCTV.</strong>
    </Typography>
        </li>
        <dd>
        Mantenimiento preventivo y correctivo.
        </dd>
        <dd>
        Tecnología IP.
        </dd>
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">

        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Creación proyectos CCTV.</strong>
    </Typography>
        </li>
        <dd>
        Desde Industriales hasta habitacionales.
        </dd>
        <dd>
        Para todo tipo de inmuebles.
        </dd>
      </ul>
      <span itemprop="description">
      Los servicios son totalmente profesionales, nos caracteriza hacer nuestro trabajo como si fuera para el uso personal, si gusta puede levantar un ticket de servicio o soporte para que uno de nuestros ejecutivos se comunique con usted.
      </span>
      <Button disabled color="secondary">ticket</Button>
    </Typography>
    
    
  );
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//H2 acordeon seccion cableado estructurado
/////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Hacordos = () => {
  const classes = useStyles();

  return (
    
    <Typography
      itemprop="name"
      className={classes.title}
      component="h2"
      color="secondary"
      gutterBottom
    >
      Cableado estructurado
    </Typography>
    
  );
};

//H3 acordeon subtitulo seccion cableado estructurado//
export const Htuacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    itemprop="name"
    className={classes.Sub}
      component="h3"
      color="inherent"
      gutterBottom
    >
      Nuestros servicios de cableado estructurado
    </Typography>
    
  );
};

//Parrafo acordeon subtitulo seccion cableado estructurado

export const Pdacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="p"
    >
    <span itemprop="description">
      El cableado estructurado es importante para comunicación interna de la empresa, sin una comunicación eficiente hay retardos en todo aquello que necesite comunicación.
      </span>
      <ul itemprop="hasOfferCatalog" itemscope itemtype="https://schema.org/OfferCatalog">
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >

      <strong>Instalación de cableado estructurado.</strong>
    </Typography>
        </li>
        <dd>
        Cableado UTP.
        </dd>
        <dd>
        Enlaces de fibra óptica e inalámbricos.
        </dd>


        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Mantenimiento de cableado estructurado.</strong>
    </Typography>
        </li>
        <dd>
        Mantenimiento preventivo y correctivo.
        </dd>
        <dd>
        Remplazo de cableado en site, idf, mdf etc...
        </dd>
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">

        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Creación proyectos.</strong>
    </Typography>
        </li>
        <dd>
        Abarcamos cualquier proyecto relacionado.
        </dd>
        <dd>
        Para todo tipo de inmuebles.
        </dd>
      </ul>
      <span itemprop="description">
      Los servicios son totalmente profesionales, nos caracteriza hacer nuestro trabajo como si fuera para el uso personal, si gusta puede levantar un ticket de servicio o soporte para que uno de nuestros ejecutivos se comunique con usted.
      </span>
      <Button disabled color="secondary">ticket</Button>
    </Typography>

);
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//H2 acordeon seccion Equipo de computo
/////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HacorTres = () => {
  const classes = useStyles();

  return (
    
    <Typography
      itemprop="name"
      className={classes.title}
      component="h2"
      color="secondary"
      gutterBottom
    >
      Equipo de cómputo
    </Typography>
    
  );
};


//H3 acordeon subtitulo seccion Equipo de computo//
export const Htpcuacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
      itemprop="name"
      className={classes.Sub}
      component="h3"
      color="inherent"
      gutterBottom
    >
      Nuestros servicios para equipos de cómputo
    </Typography>
    
  );
};

export const Htpcincord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    className={classes.Sub}
      component="h3"
      color="inherent"
      gutterBottom
    >
      Nuestros servicios de soporte técnico
    </Typography>
    
  );
};

//Parrafo acordeon subtitulo seccion Equipo de computo

export const Pdcacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="p"
    >
    <span itemprop="description">
      Sin computadora hoy en día es un hecho que ninguna empresa puede sobrevivir en esta era de información, por lo cual nosotros nos encargamos que su herramienta principal este en funcionamiento óptimo.
      </span>
      <ul itemprop="hasOfferCatalog" itemscope itemtype="https://schema.org/OfferCatalog" >
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >

      <strong>Instalación y configuración de equipos.</strong>
    </Typography>
        </li>
        <dd>
        Instalación adecuada a tipo de inmuebles y dispositivos necesarios para el correcto funcionamiento..
        </dd>
        <dd>
        Adecuación a configuraciones necesarias para el funcionamiento de su empresa 
        </dd>


        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Mantenimiento de equipos de cómputo.</strong>
    </Typography>
        </li>
        <dd>
        Mantenimiento, preventivo y correctivo.
        </dd>
        <dd>
        Remplazo y reparaciones o cambio de Hardware según necesidad.
        </dd>
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">

        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Creación proyectos.</strong>
    </Typography>
        </li>
        <dd>
        Levantamiento de necesidades en su empresa para la adquisición de un equipo correcto y funcional.
        </dd>
        <dd>
        Ahorro en gastos de adquisición planificando proyecto.
        </dd>
      </ul>
      <span itemprop="description">
      Los servicios son totalmente profesionales, nos caracteriza hacer nuestro trabajo como si fuera para el uso personal, si gusta puede levantar un ticket de servicio o soporte para que uno de nuestros ejecutivos se comunique con usted.
      </span>
      <Button disabled color="secondary">ticket</Button>
    </Typography>

);
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//H2 acordeon seccion Venta software
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const HacorCuatro= () => {
  const classes = useStyles();

  return (
    
    <Typography
      itemprop="name"
      className={classes.title}
      component="h2"
      color="secondary"
      gutterBottom
    >
      Venta de Software
    </Typography>
    
  );
};

//H3 acordeon subtitulo seccion Venta software//
export const Htptcuacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    itemprop="name"
    className={classes.Sub}
      component="h3"
      color="inherent"
      gutterBottom
    >
      Nuestros servicios para Venta de Software
    </Typography>
    
  );
};

//Parrafo acordeon subtitulo seccion Venta software

export const Pdtcacord = () => {
  const classes = useStyles();

  return (
    
    <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="p"
    >
    <span itemprop="description">
      Aveces como usuario desconoce soluciones que nos pueden hacer más simple la vida ya sea en la oficina o cualquier trabajo que tenga como herramienta un equipo de cómputo.
      </span>
      <ul itemprop="hasOfferCatalog" itemscope itemtype="https://schema.org/OfferCatalog">
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >

      <strong>Instalación y configuración de Software.</strong>
    </Typography>
        </li>
        <dd>
        Instalación de software según necesidad y sistema operativo.
        </dd>
        <dd>
        Variedad de soluciones en software.
        </dd>


        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">
        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Tipos de software.</strong>
    </Typography>
        </li>
        <dd>
        Contamos con una variedad de software, desde puntos de venta, crm,erp etc...
        </dd>
        <dd>
        Trabajamos según arquitectura que se usa en su empresa.
        </dd>
        <li itemprop="itemListElement" itemscope itemtype="https://schema.org/OfferCatalog">

        <Typography
    className={classes.Parrafo}
    variant="body2" 
    color="textSecondary" 
    component="h4"
    >
      <strong>Creación proyectos.</strong>
    </Typography>
        </li>
        <dd>
        Levantamiento de necesidades en su empresa para la adquisición de un equipo correcto y funcional.
        </dd>
        <dd>
        Ahorro en gastos de adquisición planificando proyecto.
        </dd>
      </ul>
      Los servicios son totalmente profesionales, nos caracteriza hacer nuestro trabajo como si fuera para el uso personal, si gusta puede levantar un ticket de servicio o soporte para que uno de nuestros ejecutivos se comunique con usted.
      <Button disabled color="secondary">ticket</Button>
    </Typography>

);
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////






// const titulos = document.querySelectorAll("h1");
// const subtitulo = document.querySelectorAll("h2");
// console.log(titulos);
// console.log(subtitulo);
