import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AcordeonUno from './acordeones';

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    borderRadius: 10,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CardService() {
  const classes = useStyles();
  

  return (
      <>
      <Accordion className={classes.root} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='secondary' className="animate__animated  animate__heartBeat animate__slow animate__delay-7s animate__repeat-3" />}
          aria-controls="panel6a-content"
          id="panel6-header"
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Servicios
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AcordeonUno/>
        </AccordionDetails>
      </Accordion>
      </>
      
  );
}
