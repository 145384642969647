import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  fabButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    
    
    
  },
}));

export const Scroll = () => {
  const classes = useStyles();
  return (
    <Fab color="secondary" aria-label="add" className={classes.fabButton} href="tel:+523319712295">
      <PhoneIcon />
    </Fab>
  );
};
