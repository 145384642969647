import React from "react";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

class WhatsApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.nombre = 'Hola';
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }
//función Whasts app
  handleSubmit(event) {
    if (!this.state.value) return alert("escribe tu mensaje");
    window.open("https://wa.me/523319712295?text=" + encodeURIComponent(this.state.value))
    event.preventDefault();
  }

  

  render() {
    return (
      
    <div>
    <form onSubmit={this.handleSubmit}>
      <TextField 
      type="text" 
      id="mensaje"
      for="whatsapp"
      value={this.state.value}
      label= "Mensaje"
      onChange={this.handleChange} 
      variant="outlined" 
      color="secondary"
      />
    
      <IconButton
      className="animate__animated  animate__heartBeat animate__slow animate__delay-7s animate__repeat-3"
      type="submit" 
      value="Submit"
      color="secondary"
      aria-label="enviar">
        <SendIcon />
      </IconButton>
      </form>
    </div>
    );
  }
}

export default WhatsApp;

