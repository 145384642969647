import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FacebookIcon from "@material-ui/icons/Facebook";
import Grid from "@material-ui/core/Grid";
import ichSistemas from '../images/ichsistemas.webp';
// import Button from '@material-ui/core/Button';

// import Button from '@material-ui/core/Button';
// SEO
import { Huno, Htext } from "../seo/textos";
import {AvatarLog} from "../seo/avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    borderRadius: 10,
  },
  media: {
    height: 0,
    paddingTop: 198, // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  ezquierda: {
    marginLeft: "auto",
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontSize: 14,
  },
  
}));

export default function RecipeReviewCard() {
  const classes = useStyles();


  

  return (
    <div itemscope itemtype="https://schema.org/Service">
    <Card className={classes.root}>
    <CardHeader
        avatar={<AvatarLog/>}
        action={
          <IconButton href="https://www.facebook.com/ichsistemas" target="_blank" rel="noopener"  aria-label="settings">
            <FacebookIcon />
          </IconButton>
        }
        title={<Huno />}
        subheader={<Htext/>}
      />
      <CardMedia
        className={classes.media}
        itemprop = "image"
        width="100%" height="100%"
        image={ichSistemas}
        alt="Camaras de seguridad"
        title="cctv"
      />

      
      <CardContent>
      <Grid container>
      <Grid item xs={12} sm={6}>
      <span itemprop="description">
      <Typography variant="body2" color="textSecondary" component="p">
         Somos especialistas en instalación de cámaras de seguridad, cableado estructurado, mantenimiento e instalación de equipo de cómputo.
        </Typography>
        </span>
      </Grid>
      <Grid item xs={12} sm={6}>
      <span itemprop="description">
      <Typography variant="body2" color="textSecondary" component="p">
      Aliados de la plataforma <strong>"Agenda segura"</strong> una red privada de proveedores confiables.
        </Typography>
        </span>
        {/* <IconButton color="secondary" size="small" aria-label="add an alarm">
        <BookIcon/>
      </IconButton> */}
      
      </Grid>
      </Grid>
      </CardContent>
      {/* <CardActions disableSpacing>
      
        <CardContent>
          <Typography variant="h6" component="h2" gutterBottom>
            Servicios
            
          </Typography>
        </CardContent>
        <Button 
        className={classes.ezquierda}
         color="secondary"
         onClick={handleExpandClick}
         >Ver mas</Button>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon className="animate__animated  animate__heartBeat animate__slow animate__delay-5s animate__repeat-3" color="secondary" />
        </IconButton>
      </CardActions> */}
      {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <AcordeonUno/>
        </CardContent>
      </Collapse> */}
    </Card>
    </div>
  );
}
