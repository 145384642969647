import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ProductoUno from '../components/servicios/productoUno';
import ProductoDos from '../components/servicios/productoDos';
import ProductoTres from '../components/servicios/productoTres';
const useStyles = makeStyles({
  root: {
    minWidth: "100%",
    borderRadius: 10,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  return (
    <div itemscope itemtype="https://schema.org/Product">
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
        <span itemprop="description">
          Productos o servicios más vendidos
          </span>
        </Typography>
        <Typography variant="h5" component="h2">
          Destacados
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Servicios
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ProductoUno/>
          </Grid>
          <Grid item xs={12} sm={4}>
          <ProductoDos/>
          </Grid>
          <Grid item xs={12} sm={4}>
          <ProductoTres/>
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            lorem
          </Grid> */}
        </Grid>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </div>
  );
}
