import React from "react";
import { Grid } from "@material-ui/core";
import "./App.css";
import Container from "@material-ui/core/Container";
import { Scroll } from "./app/Scroll";
import CssBaseline from "@material-ui/core/CssBaseline";
import FooterG from "./components/footer.jsx";
import Typography from "@material-ui/core/Typography";
import CardService from './components/serviCard';

//app Nav
import Toolbar from "@material-ui/core/Toolbar";
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
// import MenuIcon from '@material-ui/icons/Menu';

//componentes
import Perfil from "./components/perfil";
import Descripcion from "./components/descripcion";
import Servicios from "./components/servicio";
// import Acordeon from '../components/acordeon'
import Contacto from "./components/contacto";
//Modo dark
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { blueGrey, red } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";


// const useStyles = makeStyles({
//   bord: {
//     paddingTop: 5,
//     borderRadius: 0,
//   },
// });
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  nave: {
    padding: 5,
  },
  bord: {
    paddingTop: 2,
    borderRadius: 0,
  },
  footerg: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  affected: {
    textAlign: 'right',
  },
  unaffected: {
    flip: false,
    textAlign: 'right',
  },
}));

const App = () => {
  //AppDark/////////////////////////////////////////////////////
  const [darkMode, setDarkMode] = useState(false);
  const darkTheme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: blueGrey[50],
      },
      // secondary: {
      //   main: "#1976d2",
      // },
    },
  });

  const lightTheme = createMuiTheme({
    palette: {
      primary: red,
      // secondary: blue,
    },
  });
  /////Local stroage/////
 
  /////////////////////////////////////////////////////////////
  const classes = useStyles();
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper>
        <div className={classes.nave}>
          <nav>
            <Toolbar>
              {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
              <div variant="h6" className={classes.title}></div>
              <Button
                color="inherit"
                href="https://ichsistemas.com/crm"
                alt="ich sistemas"
                target="_blank"
                rel="noopener"
              >
                Login
              </Button>
              <IconButton
                checked={darkMode}
                onClick={() => setDarkMode(!darkMode)}
                color="secondary"
                aria-label="modo obscuro"
              >
                <Brightness4Icon />
              </IconButton>
            </Toolbar>
          </nav>
        </div>
        <CssBaseline />
        <Container maxWidth="lg" className={classes.bord}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Perfil />
              <br/>
              <span><CardService/></span>
              <br />
              <br />
              <Descripcion />
            </Grid>
            <Grid item xs={12} md={4}>
            <div itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
              <Contacto />
              <br />
              <Servicios />
              </div>
            </Grid>
          </Grid>
          <Scroll />
          <br />
          <br />
          <FooterG className={classes.footerg} />
          <div  >
        <Typography color="textSecondary" variant="caption">
            Versión 2.0.2
          </Typography>
          </div>
          <br />
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default App;
