import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Iframe from 'react-iframe'

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    borderRadius: 10,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Ubicación
        </Typography>
        <Typography variant="p" component="h3">
          Visítanos pronto
        </Typography>
        <br/>
        <Typography className={classes.title} color="textSecondary">
          Horario: Lunes a Viernes 10:00am 6:00pm Sabado 10:00am - 12:00am
        </Typography>
        <br/>
        <center>
        <div itemprop="geo" itemscope itemtype="https://schema.org/GeoCoordinates">
        <Iframe title="Google maps" url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.97811846432!2d-103.36599408545206!3d20.670469205289685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae9b074baaab%3A0x3c5d23676b4593ce!2sIch%20sistemas!5e0!3m2!1ses-419!2smx!4v1614025952342!5m2!1ses-419!2smx' frameBorder='0' passive= 'true' />
        <meta itemprop="latitude" content="20.670419" />
        <meta itemprop="longitude" content="-103.363661" />
        </div>
        </center>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}

//<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.97811846432!2d-103.36599408545206!3d20.670469205289685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae9b074baaab%3A0x3c5d23676b4593ce!2sIch%20sistemas!5e0!3m2!1ses-419!2smx!4v1614025952342!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>