import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Textos SEO
import { Hacord, Hacordos, HacorTres, HacorCuatro, Htacord, Pacord, Htuacord, Pdacord, Htpcuacord, Pdcacord, Htptcuacord, Pdtcacord } from "../seo/textos";



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


export default function AcordeonUno() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <div itemscope itemtype="https://schema.org/Service">
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
        <Hacord/>
        </AccordionSummary>
        <AccordionDetails>
          <Htacord/>
        </AccordionDetails>
        <AccordionDetails>
          <Pacord/>
        </AccordionDetails>
      </Accordion>
      </div>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Hacordos/>
        </AccordionSummary>
        <AccordionDetails>
        <Htuacord/>
        </AccordionDetails>
        <AccordionDetails>
          <Pdacord/>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <HacorTres/>
        </AccordionSummary>
        <AccordionDetails>
          <Htpcuacord/>
        </AccordionDetails>
        <AccordionDetails>
          <Pdcacord/>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <HacorCuatro/>
        </AccordionSummary>
        <AccordionDetails>
          <Htptcuacord/>
        </AccordionDetails>
        <AccordionDetails>
          <Pdtcacord/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
