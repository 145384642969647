import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
//app
import WhatsApp from '../app/WhatsApp';
import { FaceBoton } from '../seo/faceSeo';


const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    borderRadius: 10,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  notetxt:{
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  

 
  return (
    
    <Card className={classes.root}>
    <center>
      <CardContent>
      
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Envianos un WhastApp
        </Typography>
        <Typography variant="h5" component="h2">
          Contacto
        </Typography>
        <br/>
        <WhatsApp/>
        <br/>
        <Typography variant="body2" component="p">
          ¿No usas WhastApp?
          <br />
        
        </Typography>
        <Typography variant="h6" component="h3"  color="textSecondary" gutterBottom >
        <span itemprop="telephone">
        <a className={classes.notetxt} href="tel:+523319712295">(33)1971{bull}2295</a>
        </span>
      </Typography>
      <br/>
      <FaceBoton/>
      </CardContent>
      </center>
    </Card>
    
  );
}