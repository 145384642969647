import React from "react";


export const FaceBoton = () => {
  return (
      <>
    <div id="fb-root">
    <div className="fb-like" data-colorscheme="dark" data-href="https://developers.facebook.com/docs/plugins/" data-width="" data-layout="button" data-action="like" data-size="small" data-share="false"></div>
    </div>
    
    
    </>
  );
};

export const FaceComent = () => {
    return (
        <>
      <div id="fb-root">
      <div className="fb-post" data-href="https://www.facebook.com/permalink.php?story_fbid=430359147496834&amp;id=100015682579129" data-width="0" data-show-text="true"><blockquote cite="https://www.facebook.com/permalink.php?story_fbid=430359147496834&amp;id=100015682579129" class="fb-xfbml-parse-ignore"><p>Excelente servicio,trato al cliente y muy buena estética en sus instalaciones. Profesional.</p>Publicada por <a href="https://www.facebook.com/people/Natanael-Ure&#xf1;a/100015682579129">Natanael Ureña</a> en&nbsp;<a href="https://www.facebook.com/permalink.php?story_fbid=430359147496834&amp;id=100015682579129">Lunes, 26 de noviembre de 2018</a></blockquote></div>
      </div>
      
      
      </>
    );
  };
