import React from "react";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from "@material-ui/core/Grid";
import Gorilat from '../images/gorilat.webp';
import IconButton from "@material-ui/core/IconButton";
// import Avatar from "@material-ui/core/Avatar";
import CodeIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";





export default function SimpleContainer() {
  
  return (
    <footer>
    <Box bgcolor="inherit.main">
      <Grid container>
        <Grid item xs={12}>
          <br />
          <Typography color="textSecondary" variant="caption">
            <IconButton
              href="http://gorilat.com/"
              target="_blank"
              rel="noopener"
              color="secondary"
              size="small"
              aria-label="Gorilat"
            >
             <img src={ Gorilat} alt="Gorilat" width="50" height="50"/>
              {/* <Avatar alt="Gorilat" src={Gorilat} /> */}
            </IconButton >
            <strong>Created by</strong> gorilat.com {" "}
            <CodeIcon color="secondary" />
          </Typography>
        </Grid>
      </Grid>
      <br/>
    </Box>
    </footer>
  );
}