import React from 'react';
import Badge from '@material-ui/core/Badge';
// import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Logo from '../images/logo.webp';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  imgRedonda:{
    borderRadius:'100%',
}
}))(Badge);

// const SmallAvatar = withStyles((theme) => ({
//   root: {
//     width: 22,
//     height: 22,
//     border: `2px solid ${theme.palette.background.paper}`,
//   },
// }))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function AvatarLog() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {/* <Avatar  /> */}
        <img src={Logo} alt="Ich Sistemas" width="40" height="40" />
      </StyledBadge>
      {/* <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src="https://scontent.fgdl5-1.fna.fbcdn.net/v/t1.0-1/p148x148/24294361_1806314246076408_5403094492487710212_n.png?_nc_cat=107&ccb=3&_nc_sid=1eb0c7&_nc_eui2=AeFrP27FgDoaQhVC17l4YTPHroIdvsBo75iugh2-wGjvmLu3FwBp2pe3zaFPnVt3lnLgu7uGAHdbwH0rnbw5rEyj&_nc_ohc=sgK6RvyRrjQAX-QyrPP&_nc_ht=scontent.fgdl5-1.fna&_nc_tp=30&oh=feb08f00fd271952698bd931fbeac2b9&oe=605105DE" />}
      >
        <Avatar alt="Travis Howard" src="https://scontent.fgdl5-1.fna.fbcdn.net/v/t1.0-1/p148x148/24294361_1806314246076408_5403094492487710212_n.png?_nc_cat=107&ccb=3&_nc_sid=1eb0c7&_nc_eui2=AeFrP27FgDoaQhVC17l4YTPHroIdvsBo75iugh2-wGjvmLu3FwBp2pe3zaFPnVt3lnLgu7uGAHdbwH0rnbw5rEyj&_nc_ohc=sgK6RvyRrjQAX-QyrPP&_nc_ht=scontent.fgdl5-1.fna&_nc_tp=30&oh=feb08f00fd271952698bd931fbeac2b9&oe=605105DE" />
      </Badge> */}
    </div>
  );
}
